@import './variables.scss';

.hl-section-header {
  margin: 80px auto;
  text-align: center;

  &:after {
    content: ' ';
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: $main;
    margin-left: -15px;
    margin-top: -22px;
  }

  .hl-title {
    font-size: 38px;
    line-height: 38px;
    font-weight: 100;
    letter-spacing: -1px;
    color: $light-gray;
    margin-bottom: 5px;
  }

  .hl-subtitle {
    font-size: 22px;
    line-height: 22px;
    color: $light-gray;
    font-weight: 100;
    font-style: italic;
    letter-spacing: -1px;
    margin-top: 0;
  }
}
