@import 'bulma/sass/utilities/_all.sass';
@import '../../common/variables.scss';

@import 'bulma/sass/layout/section.sass';

.hl-partnerships {
  background-color: #f4f4f4;
  padding: 40px 0 80px;
  text-align: center;

  .hl-section-header {
    margin: 40px auto;
  }

  h3 {
    font-size: 30px;
    margin: 0;
    font-weight: 100;
    color: #656565;
  }

  .hl-partner {
    width: 200px;
    height: 200px;
    background-position: center center;
    background-size: contain;
    display: inline-block;
    background-repeat: no-repeat;
    margin: 10px;
    padding: 10px;
    color: #777;
    text-align: left;
    box-sizing: border-box;
    font-size: 14px;
    opacity: .9;

    &:hover {
      opacity: 1;
    }

    &-link {
      display: inline-block;
      padding: 20px;
      vertical-align: middle;
    }
  }
}

@media screen and (max-width: $tablet - 1px) {
  .hl-partnerships {
    .hl-partner-link {
      display: block;
      margin-bottom: 30px;
      padding: 0;
      width: 100%;
    }
  }
}
