@import 'bulma/sass/utilities/_all.sass';
@import '../../common/variables.scss';

@import 'bulma/sass/components/navbar.sass';
@import 'bulma/sass/layout/hero.sass';
@import 'bulma/sass/layout/section.sass';

.hl-main {
  background: linear-gradient(to top, rgba(255, 255, 255, 0.4) 19%, white 82%), url("homepage.jpg") no-repeat;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;

  h1,
  h2,
  h3 {
    color: $gray;
    font-family: $font-main;
    font-style: italic;
    line-height: 1em;
    margin: 5px 0;
    max-width: 430px;
    letter-spacing: -1px;

  }

  h1 {
    font-size: 36px;
    font-weight: 400;
    letter-spacing: -2px;
  }

  h2 {
    font-weight: 100;
  }

  h3 {
    color: $main;
    text-shadow: 0px 0px 1px $white;
    font-size: 21px;
    font-weight: 100;
  }

  &__actions {
    color: $gray;
    font-size: 14px;
    margin: 40px 0;
  }

  &__primary {
    background-color: $main;
    color: $white;
    font-size: 18px;
    margin-right: 20px;
    padding: 10px 45px;
  }

  &__secondary {
    background-color: $white;
    border: 1px solid $light-gray;
    color: $gray;
    font-size: 13px;
    margin-left: 20px;
    padding: 6px 30px;
  }
}

@media screen and (min-width: $desktop) {
  .hero-body > .container {
    padding-left: 200px;
  }
}

@media screen and (max-width: $tablet - 1px) {
  .hl-main {
    padding-top: 120px;

    &__actions {
      display: flex;
      flex-direction: column;

      > span {
        visibility: hidden;
      }
    }

    h3 {
      font-weight: 400;
      text-shadow: 0px 0px 1px #d4d3d3;
    }

    &__primary,
    &__secondary {
      align-self: flex-start;
      margin: 0;
    }
  }
}
