@import 'bulma/sass/utilities/_all.sass';
@import '../../common/variables.scss';

@import 'bulma/sass/layout/section.sass';

.hl-grid-products {
  margin-bottom: 40px;

  .hl-columns {
    display: flex;
    flex-wrap: wrap;
  }

  .hl-column {
    flex: 1 0 25%;
    box-sizing: border-box;
    margin: 5px;
    flex-direction: row;
    max-width: calc(33% - 10px);
  }

  .hl-product {
    background-color: #fff;
    border: 1px solid #e4e4e4;
    overflow: hidden;
  }

  .hl-product-image {
    height: 250px;
    background-size: cover;
    background-color: $background-gray;
    background-position: center;
    transition: all .4s ease-in-out;

    &:hover {
      // transform: scale(1.02);
    }
  }

  .hl-product-title {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    color: #777;
  }

  .hl-product-menu {
    font-size: 12px;
    color: #777;
    display: inline-block;
    padding-left: 10px;
  }

  .hl-product-top {
    padding: 10px 15px 0px;
  }

  .hl-product-description {
    padding: 0 10px 15px 15px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #777;
    min-height: 17px;
  }
}

@media screen and (min-width: $desktop) {
  .hl-grid-products {
    .column {
      flex: initial;
    // box-sizing: border-box;
    }
  }
}

@media screen and (min-width: $fullhd) {
  .hl-grid-products {
    .hl-product-image {
      height: 270px;
    }
  }
}

@media screen and (max-width: $tablet) {
  .hl-grid-products {
    .hl-column {
      flex: 100%;
      max-width: 100%;
    }

    .hl-product {
      display: grid;
    }
  }

  .hl-tags {
    padding: 0 20px;
  }
}