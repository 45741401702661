@import 'bulma/sass/utilities/_all.sass';
@import '../common/variables.scss';

@import 'bulma/sass/layout/section.sass';

.hl-footer {
  background-color: $footer-back;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 100;
  color: $footer-text;

  a {
    color: $footer-text;
  }

  ul {
    list-style-type: none;
    padding: 25px 0;
    display: inline-block;

    li { 
      display: inline;
      padding: 6px;
    }
  }

  .hl-copyright {
    padding: 40px 0;
    float: right;
  }
}

@media screen and (max-width: $desktop - 1px) {
  .hl-footer {
    ul { 
      padding-left: 20px;
    }

    .hl-copyright {
      padding-right: 20px;
    }
  }
}

@media screen and (max-width: $tablet - 1px) {
  .hl-footer {
    ul {
      display: block;
      padding: 25px 20px 0;

      li { 
        display: block; 
      }
    }

    .hl-copyright {
      float: initial;
      display: block;
      text-align: center;
      font-size: .8em;
      color: #9c9c9c;
      padding: 20px 0;
    }
  }
}