@import 'bulma/sass/utilities/_all.sass';
@import '../common/variables.scss';

@import 'bulma/sass/layout/section.sass';

.hl-page-not-found {
  overflow: hidden;
  padding-top: 136px;

  &-link {
    color: #DB338F;
    font-size: 1.2em;
    text-transform: uppercase;
  }

  .container.gray {
    color: #5d5d5d;
    font-family: $font-secondary;
    font-size: 17px;
    font-weight: 200;
    line-height: 24px;
    max-width: 850px;
    padding: 20px;
    text-align: center;

    p {
      margin: 30px 0;
    }

    h1, h2, h3, h4, h5 {
      color: #3e3e3e;
      text-transform: uppercase;
    }

    h1 {
      display: inline-block;
      font-size: 17px;

      &::after {
        content:' ';
        position: absolute;
        height: 3px;
        width: 23px;
        background: $main;
        left: 22px;
        margin-top: 28px;
      }
    }

    h2 {
      font-size: 16px;
    }

    h3 {
      font-size: 14px;
    }

    h4 {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: $tablet) {
  .hl-page-not-found {
    padding-top: 80px;
  }
}