@import 'bulma/sass/utilities/_all.sass';
@import '../../common/variables.scss';

@import 'bulma/sass/layout/section.sass';

.hl-grid-ambiences {
  .hl-ambience-image {
    margin-bottom: 30px;
    width: 100%;
  }

  .LazyLoad {
    height: 250px;

    &.is-visible {
      height: auto;
    }
  }
}

@media screen and (min-width: $desktop) {
  .hl-grid-ambiences {
    .column {
      flex: initial;
    // box-sizing: border-box;
    }

    .LazyLoad {
      height: 700px;
    }
  }
}

@media screen and (min-width: $fullhd) {

}
