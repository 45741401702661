// Fonts
$font-main: 'Open Sans', sans-serif;
$font-secondary: 'Muli', sans-serif;

// Colors
$white: #fff;
$black: #000;
$text: #737373;
$logo-text: #737373;
$main: #DB338F;
$gray: #737373;
$background-gray: #F4F4F4;
$border-gray: #D9D9D9;
$border-light-gray: #efefef;
$light-gray: #8C8C8C;
$footer-back: #272727;
$footer-text: #E6E6E6;

// Responsiveness
$gap: 64px !default;
$tablet: 769px !default;
$desktop: 960px + (2 * $gap) !default;
$widescreen: 1224px + (2 * $gap) !default;
$widescreen-enabled: true !default;
$fullhd: 1344px + (2 * $gap) !default;
$fullhd-enabled: true !default;

