@import 'bulma/sass/utilities/_all.sass';
@import '../common/variables.scss';

@import 'bulma/sass/components/navbar.sass';
// @import 'bulma/bulma.sass';


.hl-header {
  // padding: 40px 30px 20px;
  border-bottom: none;
  border-bottom-color: #FFFFFF;

  .navbar-menu a.navbar-item {
    text-transform: uppercase;
    font-weight: 100;
    font-size: 14px;

    &:hover {
      background-color: transparent;
      color: $text;
      opacity: .8;
    }
  }

  .hl-logo {
    max-height: 60px;
    padding: 20px 0;
    transition: all 0.5s ease;
  }

  .logo-text-wrapper {
    margin-top: -6px;
    transition: all 0.5s ease;
  }

  .logo-text-name,
  .logo-text-line {
    color: $logo-text;
    display: block;
    font-weight: 100;
    letter-spacing: -.7px;
    line-height: 1em;
  }

  .logo-text-name {
    color: $main;
    font-size: 1.2em;
  }
}

@media screen and (min-width: $desktop) {
  .hl-header.mini {
    border-bottom: 1px solid #efefef;
    transition: border 0.5s ease;

    .hl-logo {
      max-height: 35px;
      padding: 5px 10px 0 0;
    }

    .logo-text-wrapper {
      margin-top: 0;
      font-size: .9em;
    }
  }
}

@media screen and (max-width: $desktop - 1px) {
  .hl-header {
    .navbar-brand a.navbar-item { 
      padding: 0;
    }

    .hl-logo {
      max-height: 40px;
      padding: 0 10px 0;
    }
    .logo-text-wrapper {
      margin-top: 0;
      font-size: .9em;
    }
  }
}

@media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
  .hl-header {
    padding: 20px;

    #navbarMenu {
      width: 320px;
      float: right;
    }
  }
}

@media screen and (max-width: $tablet - 1px) {
  .hl-header {
    padding: 10px;
  }
}