@import 'bulma/sass/utilities/_all.sass';
@import '../common/variables.scss';

@import 'bulma/sass/layout/section.sass';

.hl-page-products {
  min-height: 70%;
  overflow: hidden;
  padding-top: 136px;

 .left {
    width: 240px;
    float: left;
 }
 
 .right {
     float: none;
     width: auto;
     overflow: hidden;
 }
}

@media screen and (max-width: $tablet) {
  .hl-page-products {
    padding-top: 80px;
    
    .left {
      width: 100;
    }
  
    .right {
      width: 100%;
    }
  }
}
