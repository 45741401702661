@import 'bulma/sass/utilities/_all.sass';
@import '../../common/variables.scss';

@import 'bulma/sass/layout/section.sass';

.hl-quote {
  background-color: #F4F4F4;
  color: $light-gray;

  > .container {
    text-align: center;
  }

  .wrapper {
    padding: 80px;
    max-width: 800px;
    display: inline-block;
    text-align: left;
  }

  h3 {
    font-weight: 400;
    font-style: italic;
    letter-spacing: -1px;
    line-height: 38px;
    font-size: 30px;
    margin: 10px;
  }

  h4 {
    font-weight: 100;
    font-size: 22px;
    line-height: 30px;
    font-style: italic;
    margin: 10px;
  }
}

@media screen and (max-width: $widescreen - 1px) {
}

@media screen and (max-width: $desktop - 1px) {
}

@media screen and (max-width: $tablet - 1px) {
  .hl-quote .wrapper {
    padding: 40px 20px;
    margin-left: 0;

    h3 {
      line-height: 32px;
      font-size: 26px;
    }

    h4 {
      font-size: 19px;
      line-height: 26px;
    }
  }
}