@import 'common/variables.scss';
@import 'common/common.scss';

@import 'bulma/sass/utilities/_all.sass';
@import 'bulma/sass/elements/container.sass';
@import 'bulma/sass/grid/columns.sass';

html, 
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-main;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100%;
  display: grid;
}

a {
  text-decoration: none;

  &:hover {
    opacity: .9;
    transition: opacity .2s ease-in-out
  }
}

.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000000;
  color: white;
  padding: 8px;
  z-index: 100;
}

.skip-link:focus {
  top: 0;
}

// Page titles
.hl-gray-wrapper {
  background-color: #F5F5F5;
  border-top: solid 1px #e4e4e4;
  padding: 40px 0;
  min-height: 500px;
}

.hl-page-title {
  text-align: center;
  padding: 25px 0;
  
  .wrapper {
    text-align: left;
    display: inline-block;
    color: #737373;
  }

  h1 {
    font-family: $font-main;
    font-weight: lighter;
    font-size: 14px;
    line-height: 14px;
    padding: 0;
    margin: 0;
  }

  h2 {
    font-family: $font-secondary;
    font-weight: 300;
    font-size: 31px;
    line-height: 32px;
    letter-spacing: -1px;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
  }
}

@media screen and (max-width: $tablet - 1px) {
  .columns {
    margin: 0;
  }

  .hl-page-title {
    text-align: center;
    padding: 25px 20px; 

    h1 {
      text-align: center;
    }

    h2 {
      font-size: 27px;
      text-align: center;
    }
  }
}