@import 'bulma/sass/utilities/_all.sass';
@import '../common/variables.scss';

@import 'bulma/sass/layout/section.sass';

.hl-page-ambiences {
  min-height: 70%;
  overflow: hidden;
  padding-top: 136px;
}

@media screen and (max-width: $tablet) {
  .hl-page-ambiences {
    padding-top: 80px;
  }
}