@import 'bulma/sass/utilities/_all.sass';
@import '../../common/variables.scss';

@import 'bulma/sass/layout/section.sass';

.hl-home-products {
  margin-bottom: 80px;

  .hl-action {
    text-align: center;
    margin: 40px 0;
  }

  .hl-button {
    background-color: #ededed;
    border: 1px solid #ededed;
    color: $gray;
    display: inline-block;
    padding: 8px 40px;
    transition: all .3s;

    &:hover {
      box-shadow: #b5b5b5 9px 8px 12px;
    }
  }

  .hl-product {
    border: 1px solid $border-light-gray;
    overflow: hidden;
  }

  .hl-product-image {
    height: 270px;
    background-size: cover;
    background-color: $background-gray;
    background-position: center;
    transition: all .4s ease-in-out;

    &:hover {
      // transform: scale(1.02);
    }
  }

  .hl-product-title {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    color: #777;
  }

  .hl-product-menu {
    font-size: 12px;
    color: #777;
    display: inline-block;
    padding-left: 10px;
  }

  .hl-product-top {
    padding: 10px 15px 0px;
  }

  .hl-product-description {
    padding: 0 5px 15px 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: #777;
    min-height: 19px;
  }
}

@media screen and (min-width: $desktop) {
  .hl-home-products {
    .column {
      flex: initial;
      box-sizing: border-box;
    }
  }
}

@media screen and (max-width: $tablet) {
  .hl-home-products {
    .hl-product {
      display: grid;
    }
  }
}
