@import "bulma/sass/utilities/_all.sass";
@import "../../common/variables.scss";

@import "bulma/sass/layout/section.sass";

.hl-home-ambience {
  margin-bottom: 80px;

  .hl-action {
    text-align: center;
    margin: 40px 0;
  }

  .hl-button {
    padding: 8px 40px;
    background-color: #ededed;
    border: 1px solid #ededed;
    color: $gray;
    display: inline-block;
    transition: all 0.3s;

    &:hover {
      box-shadow: #b5b5b5 9px 8px 12px;
    }
  }

  .hl-ambience-image {
    height: 310px;
    background-size: cover;
    background-color: $background-gray;
    background-position: center;
    transition: all 0.4s ease-in-out;
  }
}

@media screen and (max-width: $tablet - 1px) {
}

@media screen and (max-width: $tablet - 1px) {
}

@media screen and (min-width: $desktop) {
  .hl-home-ambience {
    .column {
      flex: initial;
    }
  }
}
